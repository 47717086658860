export const CGU_LINK = 'https://api.alloopharma.co/api/v1/constants/cgu';
export const PRIVACY_POLICY_LINK = 'https://api.alloopharma.co/api/v1/constants/policy';


type MappingType = Record<string, string>

export const PHARMACY_GROUPEMENT_ATTRIBUTE = [
  'Sans groupement',
  '37 degrés',
  'Act pharmacie',
  'Aelia',
  'Agir pharma',
  'Alphega',
  'Altapharm',
  'Alternativ Pharma XV',
  'Anton & Willem',
  'Apm',
  'Apofficine',
  'Apothéo',
  'Apothical',
  'Apothicoop',
  'App Côte d’Azur',
  'Aprium Pharmacie',
  'Apsara',
  'Aptiphar',
  'Artesipharm',
  'Artisan santé',
  'Aspic',
  'Avenir pharma',
  'Be pharma',
  'Boticinal',
  'Bretagne santé référence',
  'Cali pharma',
  "Cap'Unipharm",
  'Ceido',
  'Cercle des pharmaciens coopérateurs',
  'Cofisanté',
  'Coopérative des pharmaciens du sud',
  'Côté pharma',
  'Déphie',
  'DL santé',
  'Dpgs',
  'Dpo groupe',
  'Dynamis',
  'Dynaphar',
  'Eltimaje majelis',
  'Elsie',
  'Escale santé',
  'Europharma',
  'Evolupharm',
  'Excel pharma',
  'Farmax',
  'Flexi plus pharma',
  'Forum santé',
  'G plus pharmacie',
  'G-pharm',
  'G1000-pharma',
  'Galien',
  'GFC pharma',
  'Giphar',
  'Giropharm',
  'Global pharmacie',
  'Grand est pharma',
  'Gripamel',
  'Groupapharm',
  'Happy pharmacie',
  'Hello Pharmacie',
  'Hexapharm',
  'iPharm',
  'Katalys',
  'Kristal pharma',
  "Lab'officine",
  'Lafayette',
  'Le club du comptoir',
  'Le Gall',
  'Leadersanté',
  'Les officinales',
  "Les pharmaciens d'Armor",
  'Ma pharmacie',
  'Magdaléon',
  'Maxipharma',
  'Mediprix',
  'Mutualpharm',
  'Nextypharm',
  'Normandie pharma',
  'Norpharma',
  'Objectif pharma',
  'Omnespharma',
  'Optipharm',
  'Originsanté',
  'Orphie',
  'Orphie Lim',
  'Orphie Provence',
  'Oté pharma',
  'Paraph',
  'PDA pharma',
  'Pharm & Free',
  'Pharm & you',
  "Pharm O'naturel",
  'Pharm O2',
  'Pharm-Agir',
  'Pharm-Upp',
  "Pharm'Auvergne",
  "Pharm'only",
  'Pharma 10',
  'Pharma direct',
  'Pharma Platinum',
  'Pharma Provence Groupe',
  'Pharma saify',
  'Pharma sud',
  'Pharma Synergie',
  'Pharmabest',
  'Pharmabsolu',
  'COS',
  'Pharmaciens associés',
  'Pharmacies Groupe Rocade',
  'Pharmacorp',
  'Pharmactiv',
  'Pharmadinina',
  'Pharmadn',
  'Pharmafrance',
  'Pharmagen',
  'Pharmagroupsanté',
  'Pharmalys',
  'Pharmamix',
  'Pharmarem',
  'Pharmarket',
  'Pharmatomic',
  'Pharmavance',
  'Pharmavenir',
  'Pharmaveyron',
  'Pharmavie',
  'Pharmax',
  'Pharmaxial',
  'Pharmayannick',
  'Pharmazon',
  'Pharmici',
  'Pharmidée',
  "Pharm'indep",
  'Pharmodel',
  'Pharmuni',
  'Pharmup',
  'PHR Référence',
  'Positive Pharma',
  'PSD - Pharma Santé Développement',
  'PUC pharma',
  'Quartz',
  'Réseau P&P',
  'Réseau pharma Méditerranée',
  'Réseau santé',
  'Resofficine',
  'Résonor',
  'Santalis',
  'Simplypharma',
  'Socopharma',
  'Sofiadis',
  "Solution Réseau d'Achat",
  'Sopharef',
  'STC pharma',
  'Sud Pharma',
  'Sunipharma',
  'Suprapharm',
  'Synaps pharma',
  'Synergiphar',
  'Teleconsult pharma',
  'Totum',
  'Trentactiv',
  'UDGPO',
  'UGP - Union des Grandes Pharmacies',
  'Unipharm',
  'Unipharm Loire-Océan',
  'Univers pharmacie',
  'UPIE',
  'VD-pharm',
  'Vitale pharma',
  'Vitapharma',
  'Vpharma',
  'Welcoop',
  'Well & Well',
  'Wellpharma',
  'Ynova group',
]

export const ACCOUNT_GENDER_ATTRIBUTE_MAPPING: MappingType = {
  'Homme': 'homme',
  'Femme': 'femme',
  'Autre': 'autre'
}
export const PHARMACY_PARKING_ATTRIBUTE_MAPPING: MappingType = {
  'Oui, gratuit': 'free',
  'Oui, payant': 'payable',
  'Non': 'none'
}

export const PHARMACY_TYPOLOGY_ATTRIBUTE_MAPPING: MappingType = {
  'Quartier résidentiel': 'district',
  'Centre ville': 'downtown',
  'Centre Commercial': 'mall',
  'Gare ou aéroport': 'transportation',
}

export const PHARMACY_TRANSPORT_ATTRIBUTE_MAPPING: MappingType = {
  'Voiture': 'car',
  'Transports en commun': 'transport'
}
